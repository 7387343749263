@import url("https://fonts.googleapis.com/css?family=Droid+Sans+Mono");
/* * {
  box-sizing: border-box;
} */
.vl {
    border-right: 2px solid red;
    height: 40px;
    margin-top:0px;
}
.empty-div {
  height: 40px;
  margin-top:0px;
}
.milestone {
  text-align: right;
}
.milestone span {
  font-size: 11px;
  font-family: "Droid Sans Mono", monospace;
  color: #fff;
}

.progressText {
  font-size: 12px;
  font-family: "Droid Sans Mono", monospace;
  color: #fff;
  font-weight: bold;
  text-align: right;
}

.flexLines {
  padding: 0;
  margin: 0;
  list-style: none;
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-flow: nowrap;
  justify-content: flex-start;
}
.ProgressContainer {
  margin: 20px;
}
.flipClock {
  display: flex;
  justify-content: space-between;
  width: 350px;
}

.flipUnitContainer {
  /* display: block; */
  position: relative;
  width: 80px;
  height: 80px;
  -webkit-perspective-origin: 50% 50%;
          perspective-origin: 50% 50%;
  -webkit-perspective: 300px;
          perspective: 300px;
  background-color: black;
  border-radius: 10px;
  box-shadow: 0px 10px 10px -10px grey;
}
.UnitItemContainer {
  /* margin-top: 5px; */
  display: flex;
  margin: 5px auto;
  height: 25px;
  width: 80px;
  background-color: black;
  align-items: flex-start;
  text-align: center;
  border-radius: 3px;
  justify-content: center;
}
.UnitItemContainer span {
  padding:0;
  margin: 0 auto;
  color: #fff;
  font-size: 1rem;
}
.upperCard, .lowerCard {
  display: flex;
  position: relative;
  justify-content: center;
  width: 100%;
  height: 50%;
  overflow: hidden;
  text-align: center;
  align-items: center;
}
.upperCard span, .lowerCard span {
  font-size: 3em;
  font-family: "Droid Sans Mono", monospace;
  font-weight: lighter;
  color: #fff;
  /* width: 100%; */
}

.upperCard {
  align-items: flex-end;
  border-bottom: 0.25px solid #33333340;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
}
.upperCard span {
  -webkit-transform: translateY(50%);
          transform: translateY(50%);
}

.lowerCard {
  align-items: flex-start;
  border-top: 0.25px solid #33333340;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
}
.lowerCard span {
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
}

.flipCard {
  display: flex;
  justify-content: center;
  position: absolute;
  left: 0;
  width: 80px;
  height: 40px;
  overflow: hidden;
  -webkit-backface-visibility: hidden;
          backface-visibility: hidden;
}
.flipCard span {
  font-family: "Droid Sans Mono", monospace;
  font-size: 3em;
  font-weight: lighter;
  color: #fff;
}
.flipCard.unfold {
  top: 50%;
  align-items: flex-start;
  -webkit-transform-origin: 50% 0%;
          transform-origin: 50% 0%;
  -webkit-transform: rotateX(180deg);
          transform: rotateX(180deg);
  background-color: black;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  border: 1px solid #33333300;
  border-top: 0.5px solid #33333300;
}
.flipCard.unfold span {
  /* width:100%; */
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
}
.flipCard.fold {
  top: 0%;
  align-items: flex-end;
  -webkit-transform-origin: 50% 100%;
          transform-origin: 50% 100%;
  -webkit-transform: rotateX(0deg);
          transform: rotateX(0deg);
  background-color: black;
  border-top-right-radius: 10px;
  border-top-left-radius: 10px;
  border: 0.5px solid #33333345;
  border-bottom: 0.5px solid #33333345;
}
.flipCard.fold span {
  /* width:100%; */
  -webkit-transform: translateY(50%);
          transform: translateY(50%);
}

.fold {
  -webkit-animation: fold 0.6s cubic-bezier(0.955, 0.515, 0.515, 0.955) 0s 1 normal forwards;
          animation: fold 0.6s cubic-bezier(0.955, 0.515, 0.515, 0.955) 0s 1 normal forwards;
  -webkit-transform-style: preserve-3d;
          transform-style: preserve-3d;
}

.unfold {
  -webkit-animation: unfold 0.6s cubic-bezier(0.955, 0.515, 0.515, 0.955) 0s 1 normal forwards;
          animation: unfold 0.6s cubic-bezier(0.955, 0.515, 0.515, 0.955) 0s 1 normal forwards;
  -webkit-transform-style: preserve-3d;
          transform-style: preserve-3d;
}

@-webkit-keyframes fold {
  0% {
    -webkit-transform: rotateX(0deg);
            transform: rotateX(0deg);
  }
  100% {
    -webkit-transform: rotateX(-180deg);
            transform: rotateX(-180deg);
  }
}

@keyframes fold {
  0% {
    -webkit-transform: rotateX(0deg);
            transform: rotateX(0deg);
  }
  100% {
    -webkit-transform: rotateX(-180deg);
            transform: rotateX(-180deg);
  }
}
@-webkit-keyframes unfold {
  0% {
    -webkit-transform: rotateX(180deg);
            transform: rotateX(180deg);
  }
  100% {
    -webkit-transform: rotateX(0deg);
            transform: rotateX(0deg);
  }
}
@keyframes unfold {
  0% {
    -webkit-transform: rotateX(180deg);
            transform: rotateX(180deg);
  }
  100% {
    -webkit-transform: rotateX(0deg);
            transform: rotateX(0deg);
  }
}

/* Portrait and Landscape */
@media only screen
  and (min-device-width: 320px)
  and (max-device-width: 667px) {
    .flipClock {
      width: 200px;
    }
    .flipUnitContainer {
      width: 45.7px;
      height: 45.7px;
      border-radius: 10px;
    }
    .UnitItemContainer {
      /* margin-top: 5px; */
      height: 15px;
      width: 45.7px;
      border-radius: 3px;
    }
    .UnitItemContainer span {
      font-size: 0.7rem;
    }
    .flipCard {
      width: 45.7px;
      height: 22.82px;
    }
    .flipCard span {
      font-size: 1.5em;
    }
    .upperCard span, .lowerCard span {
      font-size: 1.5em;
    }
}

/* ----------- iPhone 6+, 7+ and 8+ ----------- */

/* Portrait and Landscape */
/* @media only screen
  and (min-device-width: 414px)
  and (max-device-width: 736px)
  and (-webkit-min-device-pixel-ratio: 3) {

} */

/* ----------- iPhone X ----------- */

/* Portrait and Landscape */
@media only screen
  and (min-device-width: 375px)
  and (max-device-width: 812px) {
    .flipClock {
      width: 230px;
    }
    .flipUnitContainer {
      width: 52.57px;
      height: 52.57px;
      border-radius: 10px;
    }
    .UnitItemContainer {
      /* margin-top: 5px; */
      height: 15px;
      width: 52.57px;
      border-radius: 3px;
    }
    .UnitItemContainer span {
      font-size: 0.7rem;
    }
    .flipCard {
      width: 52.57px;
      height: 26.2857px;
    }
    .flipCard span {
      font-size: 1.5em;
    }
    .upperCard span, .lowerCard span {
      font-size: 1.5em;
    }
}
