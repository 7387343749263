@media (max-width: 700px) {
  h1, .h1, #h1 {
    font-size: 20px;
  }
  h2, #h2 {
    font-size: 16px;
  }
  h3, #h3 {
    font-size: 13px;
  }
  h4, #h4 {
    font-size: 11px;
  }
  h5, #h5 {
    font-size: 9px;
  }
  h6, #h6 {
    font-size: 8px;
  }
  a, #a {
    font-size: 16px;
  }
  i, #i {
    font-size: 12px;
  }
  b, #b {
    font-size: 12px;
  }
  select, #select {
    font-size: 12px;
  }
  .my-navbar-p {
    font-size: 24px;
  }
  table {
    width: 60%;
  }
  .home-table {
    width: 90%;
  }
  /* .my-line-chart {
    margin-left: -2.5vw;
  } */
}

@media (min-width: 700px) {
  h1, .h1, #h1 {
    font-size: 28px;
  }
  h2, #h2 {
    font-size: 22px;
  }
  h3, #h3 {
    font-size: 18px;
  }
  h4, #h4 {
    font-size: 14px;
  }
  h5, #h5 {
    font-size: 12px;
  }
  h6, #h6 {
    font-size: 10px;
  }
  a, #a {
    font-size: 16px;
  }
  i, #i {
    font-size: 14px;
  }
  b, #b {
    font-size: 14px;
  }
  select, #select {
    font-size: 14px;
  }
  .my-navbar-p {
    font-size: 26px;
  }
  table {
    width: 50%;
  }
  .home-table {
    width: 60%;
  }
  /* .my-line-chart {
    margin-left: -1.5vw;
  } */
}

@media (min-width: 992px) {
  h1, .h1, #h1 {
    font-size: 30px;
  }
  h2, #h2 {
    font-size: 23px;
  }
  h3, #h3 {
    font-size: 18px;
  }
  h4, #h4 {
    font-size: 15px;
  }
  h5, #h5 {
    font-size: 13px;
  }
  h6, #h6 {
    font-size: 10px;
  }
  a, #a {
    font-size: 15px;
  }
  i, #i {
    font-size: 15px;
  }
  b, #b {
    font-size: 15px;
  }
  select, #select {
    font-size: 15px;
  }
  .my-navbar-p {
    font-size: 28px;
  }
  table {
    width: 30%;
  }
  .home-table {
    width: 40%;
  }
  .my-line-chart {
    margin-left: 0vw;
  }
}

@media (min-width: 1200px) {
  h1, .h1, #h1 {
    font-size: 32px;
  }
  h2, #h2 {
    font-size: 24px;
  }
  h3, #h3 {
    font-size: 18.72px;
  }
  h4, #h4 {
    font-size: 16px;
  }
  h5, #h5 {
    font-size: 13.28px;
  }
  h6, #h6 {
    font-size: 10.72px;
  }
  a, #a {
    font-size: 18px;
  }
  i, #i {
    font-size: 16px;
  }
  b, #b {
    font-size: 16px;
  }
  select, #select {
    font-size: 16px;
  }
  .my-navbar-p {
    font-size: 30px;
  }
  .my-line-chart {
    margin-left: 0vw;
  }
}

.my-line-chart {
  margin-left: 0vw;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: -moz-none;
  -o-user-select: none;
  user-select: none;
  -webkit-touch-callout: none;
-webkit-user-select: none;
-khtml-user-select: none;
-moz-user-select: none;
-ms-user-select: none;
user-select: none;
}

/* * {
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: -moz-none;
  -o-user-select: none;
  user-select: none;
} */