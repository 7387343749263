@import url(https://fonts.googleapis.com/css?family=Droid+Sans+Mono);
/* * {
  box-sizing: border-box;
} */
.vl {
    border-right: 2px solid red;
    height: 40px;
    margin-top:0px;
}
.empty-div {
  height: 40px;
  margin-top:0px;
}
.milestone {
  text-align: right;
}
.milestone span {
  font-size: 11px;
  font-family: "Droid Sans Mono", monospace;
  color: #fff;
}

.progressText {
  font-size: 12px;
  font-family: "Droid Sans Mono", monospace;
  color: #fff;
  font-weight: bold;
  text-align: right;
}

.flexLines {
  padding: 0;
  margin: 0;
  list-style: none;
  display: flex;
  justify-content: flex-start;
}
.ProgressContainer {
  margin: 20px;
}
.flipClock {
  display: flex;
  justify-content: space-between;
  width: 350px;
}

.flipUnitContainer {
  /* display: block; */
  position: relative;
  width: 80px;
  height: 80px;
  -webkit-perspective-origin: 50% 50%;
          perspective-origin: 50% 50%;
  -webkit-perspective: 300px;
          perspective: 300px;
  background-color: black;
  border-radius: 10px;
  box-shadow: 0px 10px 10px -10px grey;
}
.UnitItemContainer {
  /* margin-top: 5px; */
  display: flex;
  margin: 5px auto;
  height: 25px;
  width: 80px;
  background-color: black;
  align-items: flex-start;
  text-align: center;
  border-radius: 3px;
  justify-content: center;
}
.UnitItemContainer span {
  padding:0;
  margin: 0 auto;
  color: #fff;
  font-size: 1rem;
}
.upperCard, .lowerCard {
  display: flex;
  position: relative;
  justify-content: center;
  width: 100%;
  height: 50%;
  overflow: hidden;
  text-align: center;
  align-items: center;
}
.upperCard span, .lowerCard span {
  font-size: 3em;
  font-family: "Droid Sans Mono", monospace;
  font-weight: lighter;
  color: #fff;
  /* width: 100%; */
}

.upperCard {
  align-items: flex-end;
  border-bottom: 0.25px solid #33333340;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
}
.upperCard span {
  -webkit-transform: translateY(50%);
          transform: translateY(50%);
}

.lowerCard {
  align-items: flex-start;
  border-top: 0.25px solid #33333340;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
}
.lowerCard span {
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
}

.flipCard {
  display: flex;
  justify-content: center;
  position: absolute;
  left: 0;
  width: 80px;
  height: 40px;
  overflow: hidden;
  -webkit-backface-visibility: hidden;
          backface-visibility: hidden;
}
.flipCard span {
  font-family: "Droid Sans Mono", monospace;
  font-size: 3em;
  font-weight: lighter;
  color: #fff;
}
.flipCard.unfold {
  top: 50%;
  align-items: flex-start;
  -webkit-transform-origin: 50% 0%;
          transform-origin: 50% 0%;
  -webkit-transform: rotateX(180deg);
          transform: rotateX(180deg);
  background-color: black;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  border: 1px solid #33333300;
  border-top: 0.5px solid #33333300;
}
.flipCard.unfold span {
  /* width:100%; */
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
}
.flipCard.fold {
  top: 0%;
  align-items: flex-end;
  -webkit-transform-origin: 50% 100%;
          transform-origin: 50% 100%;
  -webkit-transform: rotateX(0deg);
          transform: rotateX(0deg);
  background-color: black;
  border-top-right-radius: 10px;
  border-top-left-radius: 10px;
  border: 0.5px solid #33333345;
  border-bottom: 0.5px solid #33333345;
}
.flipCard.fold span {
  /* width:100%; */
  -webkit-transform: translateY(50%);
          transform: translateY(50%);
}

.fold {
  -webkit-animation: fold 0.6s cubic-bezier(0.955, 0.515, 0.515, 0.955) 0s 1 normal forwards;
          animation: fold 0.6s cubic-bezier(0.955, 0.515, 0.515, 0.955) 0s 1 normal forwards;
  -webkit-transform-style: preserve-3d;
          transform-style: preserve-3d;
}

.unfold {
  -webkit-animation: unfold 0.6s cubic-bezier(0.955, 0.515, 0.515, 0.955) 0s 1 normal forwards;
          animation: unfold 0.6s cubic-bezier(0.955, 0.515, 0.515, 0.955) 0s 1 normal forwards;
  -webkit-transform-style: preserve-3d;
          transform-style: preserve-3d;
}

@-webkit-keyframes fold {
  0% {
    -webkit-transform: rotateX(0deg);
            transform: rotateX(0deg);
  }
  100% {
    -webkit-transform: rotateX(-180deg);
            transform: rotateX(-180deg);
  }
}

@keyframes fold {
  0% {
    -webkit-transform: rotateX(0deg);
            transform: rotateX(0deg);
  }
  100% {
    -webkit-transform: rotateX(-180deg);
            transform: rotateX(-180deg);
  }
}
@-webkit-keyframes unfold {
  0% {
    -webkit-transform: rotateX(180deg);
            transform: rotateX(180deg);
  }
  100% {
    -webkit-transform: rotateX(0deg);
            transform: rotateX(0deg);
  }
}
@keyframes unfold {
  0% {
    -webkit-transform: rotateX(180deg);
            transform: rotateX(180deg);
  }
  100% {
    -webkit-transform: rotateX(0deg);
            transform: rotateX(0deg);
  }
}

/* Portrait and Landscape */
@media only screen
  and (min-device-width: 320px)
  and (max-device-width: 667px) {
    .flipClock {
      width: 200px;
    }
    .flipUnitContainer {
      width: 45.7px;
      height: 45.7px;
      border-radius: 10px;
    }
    .UnitItemContainer {
      /* margin-top: 5px; */
      height: 15px;
      width: 45.7px;
      border-radius: 3px;
    }
    .UnitItemContainer span {
      font-size: 0.7rem;
    }
    .flipCard {
      width: 45.7px;
      height: 22.82px;
    }
    .flipCard span {
      font-size: 1.5em;
    }
    .upperCard span, .lowerCard span {
      font-size: 1.5em;
    }
}

/* ----------- iPhone 6+, 7+ and 8+ ----------- */

/* Portrait and Landscape */
/* @media only screen
  and (min-device-width: 414px)
  and (max-device-width: 736px)
  and (-webkit-min-device-pixel-ratio: 3) {

} */

/* ----------- iPhone X ----------- */

/* Portrait and Landscape */
@media only screen
  and (min-device-width: 375px)
  and (max-device-width: 812px) {
    .flipClock {
      width: 230px;
    }
    .flipUnitContainer {
      width: 52.57px;
      height: 52.57px;
      border-radius: 10px;
    }
    .UnitItemContainer {
      /* margin-top: 5px; */
      height: 15px;
      width: 52.57px;
      border-radius: 3px;
    }
    .UnitItemContainer span {
      font-size: 0.7rem;
    }
    .flipCard {
      width: 52.57px;
      height: 26.2857px;
    }
    .flipCard span {
      font-size: 1.5em;
    }
    .upperCard span, .lowerCard span {
      font-size: 1.5em;
    }
}

/* table {
  font-family: arial, sans-serif;
  border-collapse: collapse;
  width: 25%;
} */

td, th {
  border: 1px solid #dddddd;
  /* justify-content: center;
  align-items: center;
  align-content: center; */
}
/*
tr:nth-child(even) {
  background-color: #dddddd;
} */

.custom-tooltip {
  background-color: rgba(200, 200, 200, 0.3);;
  border: 2px solid rgb(210, 210, 210);
  padding: 0 15px 0 15px;
}

.label {
  margin: 5px;
  font-weight: bolder;
}

.intro, .category-stats {
  font-weight: normal;
  margin: 0;
  padding: 0;
  text-align: left;
}

.menu-item {
  outline: none;
}

.my-navbar {
  z-index: 99;
  color: black;
  background-color: white;
  height: 7vh;
  width: 100vw;
  position: fixed;
  padding-top: 1vh;
}

@media only screen
  and (min-device-width: 320px)
  and (max-device-width: 480px)
  and (-webkit-min-device-pixel-ratio: 2)
  and (orientation: landscape) {
  .my-navbar {
    height: 12vh;
  }
}

.my-navbar-link {
  color: black;
  font-weight: bold;
  display: inline-block;
  position: fixed;
  left: 10vw;
}

@media (min-width: 1200px) {
  .my-navbar-link {
    left: 5vw;
  }
}

.my-navbar-link:hover {
  color: #2B8AC5;
  text-decoration: none;
}

/* Individual item */
.bm-item {
  display: inline-block;

  /* Our sidebar item styling */
  text-decoration: none;
  color: #d1d1d1;
  transition: color 0.2s;
}

/* Change color on hover */
.bm-item:hover {
  color: white;
}

/* The rest copied directly from react-burger-menu docs */

/* Position and sizing of burger button */

.bm-burger-button {
  z-index: 999;
  position: fixed;
  width: 30px;
  height: 24px;
  right: 12px;
  top: 12px;
}

/* Color/shape of burger icon bars */
.bm-burger-bars {
  background: #373a47;
}

/* Position and sizing of clickable cross button */
.bm-cross-button {
  height: 24px;
  width: 24px;
}

/* Color/shape of close button cross */
.bm-cross {
  background: #bdc3c7;
}

/* General sidebar styles */
.bm-menu {
  background: #373a47;
  padding: 2.5em 1.5em 0;
  font-size: 1.15em;
  font-weight: bold;
  float: right;
}

@media (max-width: 700px) {
  .bm-menu {
    width: 35%;
  }

  .bm-burger-button {
    width: 30px;
    height: 24px;
  }
}

@media (min-width: 700px) {
  .bm-menu {
    width: 30%;
  }
}

@media (min-width: 992px) {
  .bm-menu {
    width: 25%;
  }
}

@media (min-width: 1200px) {
  .bm-menu {
    width: 20%;
  }
}

.bm-menu-wrap {
 position: 'fixed';
 height: 100%;
}

/* Morph shape necessary with bubble or elastic */
.bm-morph-shape {
  fill: #373a47;
}

/* Wrapper for item list */
.bm-item-list {
  color: #f2f2f2;
}

/* Styling of overlay */
.bm-overlay {
  background: rgba(0, 0, 0, 0.3);
  width: 100%;
}

button:focus {
  outline:0;
}

.menu-item {
  outline: none;
}

.App {
  height: 100%;
  width: 100%;
  text-align: center;
  background-color: #282c34;
  color: white;
  margin: 0;
  padding: 0;
}

.flipClock {
  color: black;
  margin: auto;
}

.App-content {
  margin: 0;
  padding: 0;
  background-color: #18191A;
  min-height: 100vh;
  padding-top: 15vh;
  color: white;
}

.rss-feed-post-container {
  width: 70vw;
  color: white;
  margin: auto;
  text-align: left;
  margin-bottom: 2vh;
  padding-right: 20px;
  padding-left: 20px;
  padding-top: 10px;
  padding-bottom: 20px;
  border-radius: 35px;
  background-color: #232526;
}

.fb-post-profile-pic-container {
  width: 50px;
  height: auto;
  
  display: inline-block;
}

.fb-post-profile-pic {
  float: left;
  margin: 0;
  padding: 0;
  display: block;
  color: inherit;
}

.fb-post-img {
  width: 40px;
  height: 40px;
  border-radius: 30px;
}

.fb-post-title-container {
  display: inline-block;
  margin-left: 15px;
}

.fb-post-title-text, .fb-post-title-text:hover {
  margin: 0; 
  padding: 0;
  display: block;
  color: #E1E2E8;
}

.margin-content {
  padding: 0 5vw 0 5vw;
}

.my-button {
  margin: 5px;
}
body * {
	font-family: 'Nunito', sans-serif;
	box-sizing: border-box;
}

/* body {
	background-color: #142F4C;
} */

#countdown-wrap {
  width: 100%;
  height: 300px;
  /* border: 1px solid black; */
  padding: 20px;
  font-family: arial;
  max-width: 650px;
  margin: 150px auto 300px;
}

#goal {
  font-size: 48px;
  text-align: right;
  color: #FFF;
  @media only screen and (max-width : 640px) {
    text-align: center;  
  }
  
}

#glass {
  width: 100%;
  height: 20px;
  background: #c7c7c7;
  border-radius: 10px;
  float: left;
  overflow: hidden;
}

#progress {
  float: left;
  width: 16%;
  height: 20px;
  background: #FF5D50;
  z-index: 333;
  /* border-radius: 5px; */
}

.goal-stat {
  width: 25%;
  /* height: 30px; */
  padding: 10px;
  float: left;
  margin: 0;
  color: #FFF;
  
  @media only screen and (max-width : 640px) {
    width: 50%;
    text-align: center;
  }
}

.goal-number, .goal-label {
  display: block;
}

.goal-number {
  font-weight: bold;
}

.split-screen {
  display: flex;
  flex-direction: row;
}

.half-pane {
  width: 50%;
}

.nobull {
  margin-left: -40px;
  list-style-type: none;
}

.App-link {
  color: #61dafb;
}

@media (max-width: 700px) {
  h1, .h1, #h1 {
    font-size: 20px;
  }
  h2, #h2 {
    font-size: 16px;
  }
  h3, #h3 {
    font-size: 13px;
  }
  h4, #h4 {
    font-size: 11px;
  }
  h5, #h5 {
    font-size: 9px;
  }
  h6, #h6 {
    font-size: 8px;
  }
  a, #a {
    font-size: 16px;
  }
  i, #i {
    font-size: 12px;
  }
  b, #b {
    font-size: 12px;
  }
  select, #select {
    font-size: 12px;
  }
  .my-navbar-p {
    font-size: 24px;
  }
  table {
    width: 60%;
  }
  .home-table {
    width: 90%;
  }
  /* .my-line-chart {
    margin-left: -2.5vw;
  } */
}

@media (min-width: 700px) {
  h1, .h1, #h1 {
    font-size: 28px;
  }
  h2, #h2 {
    font-size: 22px;
  }
  h3, #h3 {
    font-size: 18px;
  }
  h4, #h4 {
    font-size: 14px;
  }
  h5, #h5 {
    font-size: 12px;
  }
  h6, #h6 {
    font-size: 10px;
  }
  a, #a {
    font-size: 16px;
  }
  i, #i {
    font-size: 14px;
  }
  b, #b {
    font-size: 14px;
  }
  select, #select {
    font-size: 14px;
  }
  .my-navbar-p {
    font-size: 26px;
  }
  table {
    width: 50%;
  }
  .home-table {
    width: 60%;
  }
  /* .my-line-chart {
    margin-left: -1.5vw;
  } */
}

@media (min-width: 992px) {
  h1, .h1, #h1 {
    font-size: 30px;
  }
  h2, #h2 {
    font-size: 23px;
  }
  h3, #h3 {
    font-size: 18px;
  }
  h4, #h4 {
    font-size: 15px;
  }
  h5, #h5 {
    font-size: 13px;
  }
  h6, #h6 {
    font-size: 10px;
  }
  a, #a {
    font-size: 15px;
  }
  i, #i {
    font-size: 15px;
  }
  b, #b {
    font-size: 15px;
  }
  select, #select {
    font-size: 15px;
  }
  .my-navbar-p {
    font-size: 28px;
  }
  table {
    width: 30%;
  }
  .home-table {
    width: 40%;
  }
  .my-line-chart {
    margin-left: 0vw;
  }
}

@media (min-width: 1200px) {
  h1, .h1, #h1 {
    font-size: 32px;
  }
  h2, #h2 {
    font-size: 24px;
  }
  h3, #h3 {
    font-size: 18.72px;
  }
  h4, #h4 {
    font-size: 16px;
  }
  h5, #h5 {
    font-size: 13.28px;
  }
  h6, #h6 {
    font-size: 10.72px;
  }
  a, #a {
    font-size: 18px;
  }
  i, #i {
    font-size: 16px;
  }
  b, #b {
    font-size: 16px;
  }
  select, #select {
    font-size: 16px;
  }
  .my-navbar-p {
    font-size: 30px;
  }
  .my-line-chart {
    margin-left: 0vw;
  }
}

.my-line-chart {
  margin-left: 0vw;
  -webkit-user-select: none;
  -moz-user-select: -moz-none;
  -o-user-select: none;
  user-select: none;
  -webkit-touch-callout: none;
-webkit-user-select: none;
-moz-user-select: none;
-ms-user-select: none;
user-select: none;
}

/* * {
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: -moz-none;
  -o-user-select: none;
  user-select: none;
} */
